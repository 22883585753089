import { useMemo, useCallback } from "react";
import { Radio } from "antd";
import {
    LoadingOutlined,
    InfoCircleFilled,
    ArrowRightOutlined,
} from '@ant-design/icons';
import WithToolTip from "src/components/Common/WithToolTip";
import { snapshotFilters } from "src/constants/globalConstants"
import { getDefaultFormattedDate, getFormattedSeekValue, openFileInNewTab } from "src/utils/helperFunctions";
import { AI_PROCTORING } from "../../constant";

const SnapshotsContainer = ({
    loading = false,
    isParentSnapshotsModal = true,
    criticalSnapshots,
    allSnapshots,
    currentFilter,
    setCurrentFilter,
    linkConfig,
    positionConfig,
}) => {

    const getSnapshots = useCallback(() => {
        if (currentFilter === 'all')
            return allSnapshots
        else {
            return criticalSnapshots
        }
    }, [currentFilter, allSnapshots, criticalSnapshots, isParentSnapshotsModal])
    /**
     * Get grid class based on array length and adjust modal width accordingly
     * @param {array} snapshots - Current snapshots length (allSnapshots/criticalSnapshots)
     * @returns {object} - With grid columns class and snapCriticalWidthStyle (required only when critical snapshots array empty)
     */
    const getGridColClassAndBodyWidth = (snapshots) => {
        const styleObj = {
            gridColsStyle: '',
            snapCriticalWidthStyle: ''
        };
        if (!snapshots.length) return styleObj;
        if (snapshots.length > 0)
            return {
                snapCriticalWidthStyle: `snap-critical-width-${snapshots.length > 4 ? 4 : snapshots.length}`,
                gridColsStyle: `grid-cols-${snapshots.length > 4 ? 4 : snapshots.length}`
            };
    };

    const { gridColsStyle, snapCriticalWidthStyle } = useMemo(() => getGridColClassAndBodyWidth(allSnapshots), [allSnapshots]);

    const handleOpenFile = (fileLink) => {
        openFileInNewTab(fileLink)
    }

    return (
        <>
            {
                isParentSnapshotsModal
                    ? <>
                        <p className="text-24 weight-700">Snapshots</p>
                        <div className="flex items-center config-msg-container">
                            {
                                linkConfig
                                    ? <span
                                        className="flex items-center config-msg-icon"
                                    >
                                        <InfoCircleFilled style={{ color: '#d24444' }} className="text-18 cursor-pointer" />
                                    </span>
                                    : <></>
                            }
                            <p className={`text-12 color-black ml-4 letter-spacing-3 config-msg `}>
                                {
                                    linkConfig?.proctoring && positionConfig?.saveProctoredVideo
                                        ? <><strong>Video Proctoring</strong> was changed to <strong>Image Proctoring</strong> by admin</>
                                        : linkConfig?.proctoring === false && linkConfig?.saveProctoredVideo === false
                                            && positionConfig?.proctoring
                                            ? <><strong>Image Proctoring</strong> disabled by admin for this user</>
                                            : <></>
                                }
                            </p>
                        </div>
                        <p className={`text-14 clr-gray mt-20 ${isParentSnapshotsModal ? "text-center" : ""}`}>{criticalSnapshots.length} out of {allSnapshots.length} images are critical</p>
                    </>
                    : <></>
            }
            <div className={isParentSnapshotsModal ? "flex flex-col items-center" : ""}>
                <div className={`flex mt-2 pt-0 ${!isParentSnapshotsModal ? "justify-center" : ""}`}>
                    {
                        isParentSnapshotsModal
                            ? snapshotFilters.map(filter => <button
                                key={filter.value}
                                className={`filter-btn b-20 mr-5 ${currentFilter === filter.value ? 'selected-filter-btn text-white' : ''} txt-14 weight-600`}
                                onClick={() => setCurrentFilter(filter.value)}
                            >
                                {filter.label} {filter.value === 'all' ? `(${allSnapshots.length})` : `(${criticalSnapshots.length})`}
                            </button>
                            )
                            : <Radio.Group
                                options={snapshotFilters.map(filter => ({
                                    value: filter.value,
                                    label: `${filter.label} ${filter.value === 'all'
                                        ? `(${allSnapshots.length})`
                                        : `(${criticalSnapshots.length})`}`
                                }))}
                                onChange={({ target: { value } }) => setCurrentFilter(value)}
                                value={currentFilter}
                                optionType="button"
                                buttonStyle="solid"
                            />
                    }
                </div>
                {
                    loading
                        ? <div className="relative loader-snapshots-container">
                            <div className="absolute top-0 left-0 loader-container flex items-center justify-center b-12">
                                <LoadingOutlined />
                            </div>
                        </div>
                        : getSnapshots().length > 0 ? (
                            <div className={`grid ${isParentSnapshotsModal ? gridColsStyle : "grid-cols-6"} grid-gap-12 mt-3 mobile-grid-cols`}>
                                {
                                    getSnapshots().map(snap =>
                                        <WithToolTip
                                            showToolTip={positionConfig?.aiProctoring && snap.critical}
                                            title={snap.criticalReason}
                                        >
                                            <div key={snap.id} className='relative height-fit'>
                                                <div className="absolute top-0 left-0 snap-width snap-overlay" />
                                                <img src={snap?.media?.link ?? snap?.snapshotLink} alt='' className="snap-width" loading="lazy" />
                                                <p className="absolute bottom-0 left-0 p-10 mb-1 text-12 text-white">
                                                    {
                                                        snap?.createdAt ?
                                                            `${getDefaultFormattedDate(snap?.createdAt, "lll")} IST`
                                                            : getFormattedSeekValue(snap?.timestamp)
                                                    }
                                                </p>
                                                <span
                                                    onClick={() => handleOpenFile(snap?.media?.link ?? snap?.snapshotLink)}
                                                    className="absolute bottom-[10] right-[6] cursor-pointer"
                                                >
                                                    <ArrowRightOutlined style={{ color: "#fff", transform: "rotate(-45deg)" }} />
                                                </span>
                                                {
                                                    snap?.critical
                                                        ? <p className={`absolute txt-12 text-white critical-icon 
                                                        ${snap.criticalType === AI_PROCTORING.TYPES.CHEATING ? "critical-icon-err" : "critical-icon-warn"} select-none`}
                                                        >
                                                            !
                                                        </p>
                                                        : <></>
                                                }
                                            </div>
                                        </WithToolTip>
                                    )
                                }
                            </div>
                        ) : (
                            <div className={`${snapCriticalWidthStyle} flex items-center py-20 justify-center text-18 weight-600 clr-gray mt-5`}>
                                No snapshots found
                            </div>
                        )
                }
            </div>
        </>
    )
}

export default SnapshotsContainer
