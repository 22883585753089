import { FIT_BANDS } from "src/pages/OnePagerReport/constant";
import { countries } from "countries-list";
import { SmileEmoji, GrinEmoji, NeutralEmoji, SpiralEmoji, ScreamEmoji } from "../assets/icons";

export const TEMP_NO_FITSCORE_POSITIONS = [
  "7b919a94-018e-4605-a979-353d5d6f2fad",
  "6476f948-c307-42e8-95a9-4be0bc27415e",
  "9c34c95d-9844-4a13-a3d3-6036395b254c",
  
  // Testing - Ask before removing
  "9a612e81-b3d0-4d1c-a27d-9964757cc4eb",

  // Testing positions - Can remove freely
  // 'bf34d2a2-a888-4a0b-9aba-34c88efdd232',
  // '8ad574db-4ea3-4484-93d6-f0f5bb14e43f',
  // '03b493ed-d6da-4196-9594-2e35860f495a'
];

export const DEFAULT_COMPANY_NAME = "unberry";

export const COUNTRY_DATA = Object.keys(countries).map((code) => {
  return ({
    ...countries[code],
    countryAbrev: code
  })
});

export const DEFAULT_COUNTRY_DATA = { ...countries["IN"], countryAbrev: "IN" };

export const REFRESH_SESSION_EVENT = "REFRESH_SESSION_EVENT";

export const REFRESH_SESSION_FORM_VIEW = {
  REFRESH_TOKEN: "refresh",
  OTP_LOGIN: "otp",
};

export const DISQUALIFY_REASONS = {
  tabSwitch: "Too many tab switches",
  fullScreenExit: "Exited full screen",
  cheatingProctoring: "Caught cheating in proctoring"
};

export const ORGANIZATION_TYPES = {
  COMPANY: "company",
};

export const POSITION_TYPES = {
  COLLEGE: "college",
  DNA: "dna",
  HIRING: "hiring",
};

export const SORT_BY = 'createdAt'

export const JOB_PROFILE = {
  DESIGN: "design",
  ENGINEER: "engineer",
  DEVELOPER: "developer",
};

export const EXPERTISE_TAG = {
  "intern": "Intern",
  "individual-contributor": "IC",
  "team-lead": "TL",
  "manager": "Manager",
};

export const COLOR = {
  ORANGE: "orange",
  PURPLE: "purple",
  DARK_RED: "darkred",
};

export const PLAYER_STATUS = {
  // gameplay status
  INVITED: "invited",
  PLAYING: "playing",
  COMPLETED: "completed",
  CANCELLED: "cancelled",

  // hiring status
  UNKNOWN: "unknown",
  SHORTLISTED: "shortlisted",
  REJECTED: "rejected",
  SHORTLISTED_REJECT: "shortlisted-reject",
  HIRED: "hired",
  HIRED_REJECT: "hired-reject",
  OFFER_ROLLED_OUT: 'offer-rolled-out',
  OFFER_ROLLED_OUT_REJECT: 'offer-rolled-out-reject',
  CREATED: "created",
  OFFER_ROLLED_OUT_DROPOUT: 'offer-rolled-out-dropout',
  HIRED_DROPOUT: 'hired-dropout',
  SHORTLISTED_DROPOUT: 'shortlisted-dropout',
};

const statusLabelHelper = {
  getFormattedLabel: (text, subtext) => {
    return (<div className="flex flex-col items-start">
    <p>{text}</p>
    <p>&#40;{subtext}&#41;</p>
  </div>) 
  }
}

export const hiringStatusLabel = {
  "shortlisted-reject": statusLabelHelper.getFormattedLabel("Rejected", "Post-Shortlist"),
  "offer-rolled-out-reject": statusLabelHelper.getFormattedLabel("Rejected", "Post-Offer"),
  "hired-reject": statusLabelHelper.getFormattedLabel("Rejected", "Post-Hire"),
  "offer-rolled-out-dropout": statusLabelHelper.getFormattedLabel("Dropped Out", "Post-Offer"),
  "shortlisted-dropout": statusLabelHelper.getFormattedLabel("Dropped Out", "Post-Shortlist"),
  "hired-dropout": statusLabelHelper.getFormattedLabel("Dropped Out", "Post-Hire"),
  "rejected": "Rejected"
}

export const MENU_TAB = {
  OPEN: "open",
  ARCHIVED: "archived",
};

export const DNA_MENU_TAB = {
  PEOPLE: "people",
  TRAITS: "traits",
};

export const DUMMY_TRAITS = [
  {
    score: 7,
    traitColors: FIT_BANDS.RECOMMENDED,
  },
  {
    score: 6,
    traitColors: FIT_BANDS.RECOMMENDED,
  },
  {
    score: 8,
    traitColors: FIT_BANDS.HIGHLY,
  },
  {
    score: 5,
    traitColors: FIT_BANDS.CAUTIOUSLY,
  },
  {
    score: 4,
    traitColors: FIT_BANDS.NOT,
  },
];

export const REPORT_STATUS = {
  GENERATED: "generated",
  PENDING: "pending",
};

export const FC_LS_KEY = "fc_data";
export const MIXPANEL_LS_KEY = "mp_data";

export const MIXPANEL_EVENTS = {
  EnteredEmail: "Entered Email",
  EnteredOTP: "Entered OTP",
  ResendOTP: "Resend OTP",
  CreatedOpenPosition: "Created an open position",
  ClickedOpenPosition: "Clicked on open position",
  InvitedCandidate: "Invited a candidate",
  ViewedCandidateReport: "Viewed candidate report",
  CopiedLink: "Copied Link",
  ResendInvite: "Resend Invite",
  ShortlistCandidate: "Shortlist Candidate",
  RejectedCandidate: "Rejected Candidate",
  ClickedOnteam: "Clicked on team",
  // SearchedCandidate:"Searched Candidate",
  // FilteredByGameplayStatus:"Filtered by Gameplay Status",
  // FilteredByReportStatus:"Filtered by Report Status",
  InviteTeamMember: "Invite a team member",
  ArchivedPosition: "Archived a position",
  ViewedFullReport: "Viewed full report",
  ViewedHowToReadReport: "Viewed How to read report",
  ViewedHowToProbe: "Viewed How to probe",
  LoggedOut: "Logged out",
};

export const experienceOptions = [
  { name: "Intern", value: "intern" },
  { name: "Individual Contributor", value: "individual-contributor" },
  { name: "Team Lead", value: "team-lead" },
  { name: "Manager", value: "manager" },
];

export const BUCKET_NAMES = {
  TECHNICAL: "Technical Skills",
  SALES_KNOWLEDGE: "Sales Knowledge",
  CUSTOMER_FOCUS: "Customer Focus",
  MARKETING_COMMUNICATION: "Marketing Communication",
  SOCIAL_MEDIA_MARKETING: "Social Media Marketing",
  PRODUCT_MANAGEMENT: "Product Management",
  FUNCTIONAL: "Functional Skills",

  COMMUNICATION: "Communication",

  /** Used by PersonalityComponent */
  PERSONALITY_DIMENSIONS: "Personality Dimensions",
  IPIP_SHORT: "IPIP Short",
  IPIP_LONG: "IPIP Long",
  /***/

  COMPETENCIES: "Competencies",

  APTITUDE_AND_REASONING: "Aptitude & Reasoning"
};

export const snapshotFilters = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Critical',
    value: 'critical'
  },
]

export const MEDIA_PROCTORING_MODAL_TABS = {
  PROFILE: "Profile",
  SNAPSHOTS: "Snapshots",
  AV_RECORDING: "AV Recording",
}

export const PUBLIC_LINK_TYPES = {
  PUBLIC_SIGNUP: "register",
  PUBLIC_LOGIN: "login"
};

export const LOGIN_TYPE = {
  EMAIL: "email",
  PHONE: "phone",
  THIRD_PARTY: "thirdParty"
};

// For traits with bucketFlags 
// Rendere optionally only for given bucket names
export const PERSONALITY_TRAITS = {
  "Honesty-Humility": {
    "color": '#5CA73D',
    "bucketFlags": [BUCKET_NAMES.PERSONALITY_DIMENSIONS]
  },
  "Emotionality": {
    "color": '#EBBF3E',
    "bucketFlags": [BUCKET_NAMES.PERSONALITY_DIMENSIONS]
  },
  "Extraversion": {
    "color": '#EC9586'
  },
  "Agreeableness": {
    "color": '#E88BA0'
  },
  "Conscientiousness": {
    "color": '#74419E'
  },
  "Openness to Experience": {
    "color": '#2890CB'
  },
  "Neuroticism": {
    "color": '#DC2934',
    "bucketFlags": [BUCKET_NAMES.IPIP_LONG, BUCKET_NAMES.IPIP_SHORT]
  }
};

export const PERSONALITY_TRAITS_MAP = {
  "Honesty-Humility": {
    "median": 6.8,
    "lb": 5.95,
    "ub": 7.65,
    "description": "Being sincere, fair, and modest, with a lack of greed or materialistic tendencies.",
    "parentTrait": null
  },
  "Sincerity": {
    "median": 6.39,
    "lb": 5.34,
    "ub": 7.45,
    "description": "Being genuine, honest, and straightforward in one's interactions",
    "parentTrait": "Honesty-Humility"
  },
  "Fairness": {
    "median": 7.71,
    "lb": 6.28,
    "ub": 9.13,
    "description": "Valuing justice, equality, and treating others impartially.",
    "parentTrait": "Honesty-Humility"
  },
  "Greed-Avoidance": {
    "median": 6.23,
    "lb": 4.76,
    "ub": 7.7,
    "description": "Having a low desire to display material possessions and wealth.",
    "parentTrait": "Honesty-Humility"
  },
  "Modesty": {
    "median": 6.87,
    "lb": 5.67,
    "ub": 8.06,
    "description": "Being humble, unassuming, and avoiding self-promotion.",
    "parentTrait": "Honesty-Humility"
  },
  "Emotionality": {
    "median": 5.87,
    "lb": 5.09,
    "ub": 6.65,
    "description": "Experiencing and expressing emotions intensely.",
    "parentTrait": null
  },
  "Fearfulness": {
    "median": 6.05,
    "lb": 4.75,
    "ub": 7.35,
    "description": "Tendency to feel anxious, fearful, and easily stressed.",
    "parentTrait": "Emotionality"
  },
  "Anxiety": {
    "median": 4.76,
    "lb": 3.28,
    "ub": 6.24,
    "description": "Tendency to experience worry and nervousness.",
    "parentTrait": "Emotionality"
  },
  "Dependence": {
    "median": 6.35,
    "lb": 5.47,
    "ub": 7.24,
    "description": "Reliance on others for support, guidance, and decision-making.",
    "parentTrait": "Emotionality"
  },
  "Sentimentality": {
    "median": 6.32,
    "lb": 5.34,
    "ub": 7.3,
    "description": "Emotional responsiveness, being moved by sentimental experiences.",
    "parentTrait": "Emotionality"
  },
  "Extraversion": {
    "median": 7.73,
    "lb": 6.8,
    "ub": 8.65,
    "description": "Being outgoing, sociable, and seeking stimulation from the external environment.",
    "parentTrait": null
  },
  "Social Self-Esteem": {
    "median": 7.58,
    "lb": 6.44,
    "ub": 8.72,
    "description": "Confidence and positive evaluation of oneself in social situations.",
    "parentTrait": "Extraversion"
  },
  "Social Boldness": {
    "median": 8.07,
    "lb": 6.85,
    "ub": 9.29,
    "description": "Being socially confident, courageous, and willing to take risks.",
    "parentTrait": "Extraversion"
  },
  "Sociability": {
    "median": 7.56,
    "lb": 6.3,
    "ub": 8.83,
    "description": "Enjoying and seeking social interactions and company.",
    "parentTrait": "Extraversion"
  },
  "Liveliness": {
    "median": 7.72,
    "lb": 6.68,
    "ub": 8.75,
    "description": "Being energetic, animated, and enthusiastic in behavior.",
    "parentTrait": "Extraversion"
  },
  "Agreeableness": {
    "median": 6.64,
    "lb": 5.85,
    "ub": 7.43,
    "description": "Being cooperative, considerate, and empathetic towards others.",
    "parentTrait": null
  },
  "Forgivingness": {
    "median": 6.27,
    "lb": 5.18,
    "ub": 7.36,
    "description": "Willingness to pardon others and let go of grudges.",
    "parentTrait": "Agreeableness"
  },
  "Gentleness": {
    "median": 6.13,
    "lb": 5.14,
    "ub": 7.13,
    "description": "Having a kind, tender, and nurturing nature towards others.",
    "parentTrait": "Agreeableness"
  },
  "Flexibility": {
    "median": 6.47,
    "lb": 5.14,
    "ub": 7.79,
    "description": "Being adaptable, open-minded, and willing to change.",
    "parentTrait": "Agreeableness"
  },
  "Patience": {
    "median": 7.56,
    "lb": 6.46,
    "ub": 8.67,
    "description": "Ability to tolerate delays, frustrations, and obstacles.",
    "parentTrait": "Agreeableness"
  },
  "Conscientiousness": {
    "median": 7.45,
    "lb": 6.64,
    "ub": 8.25,
    "description": "Demonstrating self-discipline, organization, and goal-directed behavior.",
    "parentTrait": null
  },
  "Organization": {
    "median": 7.83,
    "lb": 6.54,
    "ub": 9.12,
    "description": "Being orderly, methodical, and diligent in tasks.",
    "parentTrait": "Conscientiousness"
  },
  "Diligence": {
    "median": 8.19,
    "lb": 7.1,
    "ub": 9.29,
    "description": "Having a strong work ethic, perseverance, and thoroughness.",
    "parentTrait": "Conscientiousness"
  },
  "Perfectionism": {
    "median": 8.07,
    "lb": 7.01,
    "ub": 9.12,
    "description": "Striving for high standards and meticulous attention to detail.",
    "parentTrait": "Conscientiousness"
  },
  "Prudence": {
    "median": 5.71,
    "lb": 4.35,
    "ub": 7.06,
    "description": "Exercising caution, foresight, and careful decision-making.",
    "parentTrait": "Conscientiousness"
  },
  "Openness to Experience": {
    "median": 7.9,
    "lb": 7.07,
    "ub": 8.74,
    "description": "Being receptive to new ideas, creative, and intellectually curious.",
    "parentTrait": null
  },
  "Aesthetic Appreciation": {
    "median": 8.22,
    "lb": 7.13,
    "ub": 9.32,
    "description": "Valuing and enjoying art, beauty, and creativity.",
    "parentTrait": "Openness to Experience"
  },
  "Inquisitiveness": {
    "median": 8.05,
    "lb": 6.76,
    "ub": 9.34,
    "description": "Having a curious and inquiring mind, seeking knowledge.",
    "parentTrait": "Openness to Experience"
  },
  "Creativity": {
    "median": 8.12,
    "lb": 7.05,
    "ub": 9.19,
    "description": "Being imaginative, innovative, and generating novel ideas.",
    "parentTrait": "Openness to Experience"
  },
  "Unconventionality": {
    "median": 7.23,
    "lb": 6.22,
    "ub": 8.25,
    "description": "Willingness to challenge norms, unconventional thinking.",
    "parentTrait": "Openness to Experience"
  }
};

// For two IPIP buckets - IPIP Short and IPIP Long
// Created single map with distinction within keys for Short and Long buckets
export const PERSONALITY_TRAITS_MAP_IPIP = {
  "Agreeableness": {
    "lb_short": 6.75,
    "ub_short": 8.68,
    "median_short": 7.72,
    "lb_long": 6.43,
    "ub_long": 8.18,
    "median_long": 7.31,
    "description": "Valuing getting along with othefrs, are often considerate, friendly, generous, helpful, and willing to compromise their interests with others'",
    "parentTrait": null
  },
  "Trust": {
    "lb_short": 6.19,
    "ub_short": 9.14,
    "median_short": 7.67,
    "lb_long": 6.36,
    "ub_long": 9.07,
    "median_long": 7.71,
    "description": "Assuming that most people are fair, honest, and have good intentions.",
    "parentTrait": "Agreeableness"
  },
  "Morality": {
    "lb_short": 7.0,
    "ub_short": 10.44,
    "median_short": 8.72,
    "lb_long": 6.9,
    "ub_long": 10.16,
    "median_long": 8.53,
    "description": "Not believing in pretense and manipulation, being candid, frank, and sincere",
    "parentTrait": "Agreeableness"
  },
  "Altruism": {
    "lb_short": 7.91,
    "ub_short": 10.35,
    "median_short": 9.13,
    "lb_long": 6.24,
    "ub_long": 7.98,
    "median_long": 7.11,
    "description": "Generally willing to assist those who are in need. ",
    "parentTrait": "Agreeableness"
  },
  "Cooperation": {
    "lb_short": 7.08,
    "ub_short": 10.6,
    "median_short": 8.84,
    "lb_long": 5.94,
    "ub_long": 9.2,
    "median_long": 7.57,
    "description": "Willingness to compromise or to deny one's own needs in order to get along with others",
    "parentTrait": "Agreeableness"
  },
  "Modesty": {
    "lb_short": 2.67,
    "ub_short": 6.97,
    "median_short": 4.82,
    "lb_long": 2.97,
    "ub_long": 7.09,
    "median_long": 5.03,
    "description": "Not bragging about oneself. They do not like to claim that they are better than other people.",
    "parentTrait": "Agreeableness"
  },
  "Sympathy": {
    "lb_short": 6.35,
    "ub_short": 9.48,
    "median_short": 7.91,
    "lb_long": 6.35,
    "ub_long": 9.48,
    "median_long": 7.91,
    "description": "Being tenderhearted and compassionate",
    "parentTrait": "Agreeableness"
  },
  "Conscientiousness": {
    "lb_short": 7.36,
    "ub_short": 9.64,
    "median_short": 8.5,
    "lb_long": 7.43,
    "ub_long": 9.59,
    "median_long": 8.51,
    "description": "The tendency to be organized, responsible, and hardworking,",
    "parentTrait": null
  },
  "Self-efficacy": {
    "lb_short": 8.53,
    "ub_short": 10.53,
    "median_short": 9.53,
    "lb_long": 8.39,
    "ub_long": 10.38,
    "median_long": 9.39,
    "description": "Believing that one has the intelligence, drive, and self-control necessary for achieving success",
    "parentTrait": "Conscientiousness"
  },
  "Orderliness": {
    "lb_short": 6.16,
    "ub_short": 10.27,
    "median_short": 8.21,
    "lb_long": 6.55,
    "ub_long": 9.94,
    "median_long": 8.24,
    "description": "Being well-organized, living according to routines and schedules",
    "parentTrait": "Conscientiousness"
  },
  "Dutifulness": {
    "lb_short": 7.64,
    "ub_short": 10.16,
    "median_short": 8.9,
    "lb_long": 7.64,
    "ub_long": 10.16,
    "median_long": 8.9,
    "description": "Having a strong sense of duty and obligation",
    "parentTrait": "Conscientiousness"
  },
  "Achievement-striving": {
    "lb_short": 3.68,
    "ub_short": 8.92,
    "median_short": 6.3,
    "lb_long": 6.34,
    "ub_long": 9.24,
    "median_long": 7.79,
    "description": "Being driven to be recognized as successful with a strong sense of direction in life",
    "parentTrait": "Conscientiousness"
  },
  "Self-discipline": {
    "lb_short": 7.7,
    "ub_short": 10.38,
    "median_short": 9.04,
    "lb_long": 7.05,
    "ub_long": 9.85,
    "median_long": 8.45,
    "description": "Being able to overcome reluctance to begin tasks and stay on track despite distractions",
    "parentTrait": "Conscientiousness"
  },
  "Cautiousness": {
    "lb_short": 6.37,
    "ub_short": 10.37,
    "median_short": 8.37,
    "lb_long": 6.34,
    "ub_long": 10.21,
    "median_long": 8.28,
    "description": "Being able to to think through possibilities before acting.",
    "parentTrait": "Conscientiousness"
  },
  "Extraversion": {
    "lb_short": 6.53,
    "ub_short": 8.58,
    "median_short": 7.55,
    "lb_long": 6.62,
    "ub_long": 8.44,
    "median_long": 7.53,
    "description": "Being full of energy, action- oriented and often experience positive emotions",
    "parentTrait": null
  },
  "Friendliness": {
    "lb_short": 6.46,
    "ub_short": 9.87,
    "median_short": 8.17,
    "lb_long": 6.46,
    "ub_long": 9.87,
    "median_long": 8.17,
    "description": "Being able to make friends quickly and form close, intimate relationships",
    "parentTrait": "Extraversion"
  },
  "Gregariousness": {
    "lb_short": 4.57,
    "ub_short": 8.82,
    "median_short": 6.7,
    "lb_long": 4.57,
    "ub_long": 8.82,
    "median_long": 6.7,
    "description": " Finding the company of others pleasantly stimulating and rewarding. ",
    "parentTrait": "Extraversion"
  },
  "Assertiveness": {
    "lb_short": 6.81,
    "ub_short": 10.04,
    "median_short": 8.42,
    "lb_long": 6.53,
    "ub_long": 9.41,
    "median_long": 7.97,
    "description": "Being able to speak out, take charge, and direct the activities of others.",
    "parentTrait": "Extraversion"
  },
  "Activity Level": {
    "lb_short": 5.33,
    "ub_short": 8.7,
    "median_short": 7.01,
    "lb_long": 5.0,
    "ub_long": 7.7,
    "median_long": 6.35,
    "description": "Ability to move about quickly, energetically, and vigorously, and being involved in many activities. ",
    "parentTrait": "Extraversion"
  },
  "Excitement-seeking": {
    "lb_short": 2.74,
    "ub_short": 7.71,
    "median_short": 5.23,
    "lb_long": 5.73,
    "ub_long": 8.62,
    "median_long": 7.17,
    "description": "Tendency to take risks and seek thrills and are easily bored without high levels of stimulation.",
    "parentTrait": "Extraversion"
  },
  "Cheerfulness": {
    "lb_short": 7.86,
    "ub_short": 10.35,
    "median_short": 9.1,
    "lb_long": 7.64,
    "ub_long": 10.06,
    "median_long": 8.85,
    "description": "Presence of positive mood and feelings, including happiness, enthusiasm, optimism, and joy",
    "parentTrait": "Extraversion"
  },
  "Neuroticism": {
    "lb_short": 2.89,
    "ub_short": 5.78,
    "median_short": 4.34,
    "lb_long": 3.41,
    "ub_long": 5.68,
    "median_long": 4.55,
    "description": "Tendency to experience negative feelings such as anxiety, anger, or depression",
    "parentTrait": null
  },
  "Anxiety": {
    "lb_short": 3.5,
    "ub_short": 8.03,
    "median_short": 5.76,
    "lb_long": 3.35,
    "ub_long": 7.6,
    "median_long": 5.47,
    "description": "Often feeling tense, jittery, and nervous",
    "parentTrait": "Neuroticism"
  },
  "Anger": {
    "lb_short": 1.45,
    "ub_short": 5.66,
    "median_short": 3.56,
    "lb_long": 2.07,
    "ub_long": 5.67,
    "median_long": 3.87,
    "description": "Tendency to feel enraged when things do not go one's way",
    "parentTrait": "Neuroticism"
  },
  "Depression": {
    "lb_short": 1.52,
    "ub_short": 5.36,
    "median_short": 3.44,
    "lb_long": 2.45,
    "ub_long": 5.29,
    "median_long": 3.87,
    "description": "Tendency to feel sad, dejected, and discouraged",
    "parentTrait": "Neuroticism"
  },
  "Self-consciousness": {
    "lb_short": 2.61,
    "ub_short": 7.06,
    "median_short": 4.83,
    "lb_long": 2.93,
    "ub_long": 6.58,
    "median_long": 4.76,
    "description": "Being sensitive about one's attributes and excessively concern about the impression one makes on others, which may lead to awkwardness",
    "parentTrait": "Neuroticism"
  },
  "Immoderation": {
    "lb_short": 2.23,
    "ub_short": 6.07,
    "median_short": 4.15,
    "lb_long": 3.83,
    "ub_long": 6.43,
    "median_long": 5.13,
    "description": "Having strong cravings and urges that may be difficult to resist",
    "parentTrait": "Neuroticism"
  },
  "Vulnerability": {
    "lb_short": 1.59,
    "ub_short": 5.83,
    "median_short": 3.71,
    "lb_long": 2.64,
    "ub_long": 5.7,
    "median_long": 4.17,
    "description": "Tendency to experience panic, confusion, and helplessness when under pressure or stress",
    "parentTrait": "Neuroticism"
  },
  "Openness to Experience": {
    "lb_short": 5.77,
    "ub_short": 7.82,
    "median_short": 6.8,
    "lb_long": 1.72,
    "ub_long": 12.88,
    "median_long": 7.3,
    "description": "Being intellectually curious, appreciative of art, and sensitive to beauty. ",
    "parentTrait": null
  },
  "Imagination": {
    "lb_short": 3.35,
    "ub_short": 8.41,
    "median_short": 5.88,
    "lb_long": 4.93,
    "ub_long": 8.5,
    "median_long": 6.71,
    "description": "Tendency to use fantasy as a way of creating a richer, more interesting world",
    "parentTrait": "Openness to experience"
  },
  "Artistic Interests": {
    "lb_short": 5.68,
    "ub_short": 10.28,
    "median_short": 7.98,
    "lb_long": 7.13,
    "ub_long": 10.04,
    "median_long": 8.58,
    "description": "Having interest in, and appreciation of natural and artificial beauty. ",
    "parentTrait": "Openness to experience"
  },
  "Emotionality": {
    "lb_short": 5.14,
    "ub_short": 9.45,
    "median_short": 7.29,
    "lb_long": 6.37,
    "ub_long": 9.15,
    "median_long": 7.76,
    "description": "Being aware of one's own feelings",
    "parentTrait": "Openness to experience"
  },
  "Adventurousness": {
    "lb_short": 4.36,
    "ub_short": 8.54,
    "median_short": 6.45,
    "lb_long": 5.1,
    "ub_long": 8.38,
    "median_long": 6.74,
    "description": "Eagerness to try new activities, travel to foreign lands, and experience different things.",
    "parentTrait": "Openness to experience"
  },
  "Intellect": {
    "lb_short": 5.43,
    "ub_short": 9.54,
    "median_short": 7.49,
    "lb_long": 6.17,
    "ub_long": 9.51,
    "median_long": 7.84,
    "description": "Being open-minded to new and unusual ideas, and enjoying riddles, puzzles, and brain teasers.",
    "parentTrait": "Openness to experience"
  },
  "Liberalism": {
    "lb_short": 4.95,
    "ub_short": 7.37,
    "median_short": 6.16,
    "lb_long": 4.95,
    "ub_long": 7.37,
    "median_long": 6.16,
    "description": "Readiness to challenge authority, convention, and traditional values",
    "parentTrait": "Openness to experience"
  }
};

export const feedbackFeelingsArray = [
  {
    key: "mind_blown",
    label: "Mind Blown",
    icon: SmileEmoji
  },
  {
    key: "happy",
    label: "Happy",
    icon: GrinEmoji
  },
  {
    key: "neutral",
    label: "Neutral",
    icon: NeutralEmoji
  },
  {
    key: "exhausted",
    label: "Exhausted",
    icon: SpiralEmoji
  },
  {
    key: "anxious",
    label: "Anxious",
    icon: ScreamEmoji
  },
]

export const ratingMarks = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  10: "10",
}