import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { BUCKET_NAMES, TEMP_NO_FITSCORE_POSITIONS } from "src/constants/globalConstants";
import { roundOff } from "src/pages/PdfReport/operations";
import PdfReportApi from "src/services/apis/pdfReport";
import { capitalizeTitle, getFeaturedBuckets, getPersonalityTraits, getTraitsMap } from "src/utils/helperFunctions";
import FitScore from "../FitScore";
import ReportHeader from "../Header";
import { getTraitBand } from "../oprations";
import TraitsGrid from "../TraitsGrid";
import BucketDetails from "./BucketDetails";
import SnapshotsModal from "./SnapshotsModal"
import PersonalityComponent from "../PersonalityComponent/PersonalityComponent";
import ProctoredMediaModal from "./ProctoredMedia/ProctoredMediaModal";
import { AI_PROCTORING } from "../constant";
import './v2.scss'

export const FUNCTIONAL_BUCKETS = Object.values(BUCKET_NAMES).filter(
  (bucket) =>
    ![
      BUCKET_NAMES.COMMUNICATION,
      /** Personality Component Buckets */
      BUCKET_NAMES.PERSONALITY_DIMENSIONS,
      BUCKET_NAMES.IPIP_LONG,
      BUCKET_NAMES.IPIP_SHORT,
      /***/
      BUCKET_NAMES.COMPETENCIES,
      BUCKET_NAMES.APTITUDE_AND_REASONING
    ].includes(bucket)
);

export const V2 = ({ reportData, isNonDnaReport, logMixpanelEvent }) => {
  const [activeBucketId, setActiveBucketId] = useState(null);
  const [activeTrait, setActiveTrait] = useState(null);
  const [allSnapshots, setAllSnapshots] = useState([])
  const [criticalSnapshots, setCriticalSnapshots] = useState([])
  const hoverDelayRef = useRef(null);
  const [isSnapshotsModalVisible, setIsSnapshotsModalVisible] = useState(false)
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false)
  const [videoSessionsList, setVideoSessionsList] = useState([])
  const [linkConfig, setLinkConfig] = useState(null)
  const [positionConfig, setPositionConfig] = useState(null)
  const { linkId } = useParams();


  useEffect(() => {
    const scrolledWindow = document.querySelector(".content-wrapper");
    if (scrolledWindow) {
      scrolledWindow.scrollTo(0, 0);
    }
  }, [activeBucketId]);

  useEffect(() => {
    fetchLinkConfig()
    fetchPositionConfig()
  }, [])

  useEffect(() => {
    if (!!positionConfig) {
      fetchSnapshots()
      fetchVideoSessions()
    }
  }, [positionConfig])

  const fetchPositionConfig = async () => {
    try {
      const response = await PdfReportApi.getPositionConfig(linkId)
      if (response.success) {
        setPositionConfig(response.positionConfig)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const fetchLinkConfig = async () => {
    try {
      const response = await PdfReportApi.getLinkPositionConfig(linkId)
      if (response.success) {
        setLinkConfig(response.linkPositionConfig)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const fetchVideoSessions = async () => {
    try {
      const response = await PdfReportApi.getVideoSessions(linkId)
      if (response.success) {
        setVideoSessionsList(response.data)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const fetchSnapshots = async () => {
    try {
      const response = await PdfReportApi.fetchSnapshots(linkId);
      if (response.success) {
        let updatedSnapshotsData
        if (positionConfig.aiProctoring) {
          updatedSnapshotsData = response.data
            .filter(snap => !!snap.aiProctoringData)
            .map(snap => ({
              ...snap,
              critical: [AI_PROCTORING.TYPES.CHEATING, AI_PROCTORING.TYPES.MAYBECHEATING].includes(snap.aiProctoringData?.status),
              criticalType: snap.aiProctoringData?.status,
              criticalReason: <>
                <p className="text-white text-14 weight-700 mb-4">{capitalizeTitle(snap.aiProctoringData?.type)}</p>
                <p className="text-white text-12 weight-400">{snap.aiProctoringData?.remark}</p>
              </>
            })
            )
        } else {
          updatedSnapshotsData = response.data.map(snap => ({ ...snap, criticalType: AI_PROCTORING.TYPES.CHEATING }))
        }
        setAllSnapshots(updatedSnapshotsData)
        setCriticalSnapshots(updatedSnapshotsData.filter(snap => snap.critical))
      }
    } catch (e) {
      console.error(e);
    }
  };

  console.log('reportData :>> ', reportData?.buckets);

  /** Special buckets */
  const functionalBuckets = useMemo(() => {
    const buckets = getFeaturedBuckets(reportData?.buckets, FUNCTIONAL_BUCKETS);
    return {
      name: "FUNCTIONAL SKILLS",
      description: "How proficient they are on their domain centric knowledge",
      buckets,
    };
  }, [reportData?.buckets]);
  const communicationBuckets = useMemo(() => {
    const buckets = getFeaturedBuckets(reportData?.buckets, [
      BUCKET_NAMES.COMMUNICATION,
    ]);
    return {
      name: "COMMUNICATION SKILLS",
      description: "How they understand speech and express themselves",
      buckets,
    };
  }, [reportData?.buckets]);
  const competenciesBuckets = useMemo(() => {
    const buckets = getFeaturedBuckets(reportData?.buckets, [
      BUCKET_NAMES.COMPETENCIES,
    ]);
    return {
      name: "COMPETENCIES",
      description: "How they control & conduct themselves, solve problems and work with others and react to different situations",
      buckets,
    };
  }, [reportData?.buckets]);
  const aptitudeBuckets = useMemo(() => {
    const buckets = getFeaturedBuckets(reportData?.buckets, [
      BUCKET_NAMES.APTITUDE_AND_REASONING,
    ]);
    return {
      name: "APTITUDES & REASONING",
      description: "How they interpret information, apply logic to solve problems and draw conclusions",
      buckets,
    };
  }, [reportData?.buckets]);

  // TODO: Revise code for special buckets

  const personalityBuckets = useMemo(() => {
    const traits = getPersonalityTraits(reportData?.buckets, [
      BUCKET_NAMES.PERSONALITY_DIMENSIONS,
    ]);
    return {
      name: BUCKET_NAMES.PERSONALITY_DIMENSIONS,
      traits,
    };
  }, [reportData?.buckets]);

  const personalityBucketsIPIPLong = useMemo(() => {
    const traits = getPersonalityTraits(reportData?.buckets, [
      BUCKET_NAMES.IPIP_LONG,
    ]);
    return {
      name: BUCKET_NAMES.IPIP_LONG,
      traits,
    };
  }, [reportData?.buckets]);

  const personalityBucketsIPIPShort = useMemo(() => {
    const traits = getPersonalityTraits(reportData?.buckets, [
      BUCKET_NAMES.IPIP_SHORT,
    ]);
    return {
      name: BUCKET_NAMES.IPIP_SHORT,
      traits,
    };
  }, [reportData?.buckets]);

  // Check if reportData?.buckets contain only Personality bucket
  // True -> Hide Fitscore Component
  const checkOnlyPersonalityBucket = useMemo(() => {
    if (reportData?.buckets
      && reportData?.buckets.length === 1
      && (
        reportData?.buckets[0].name === BUCKET_NAMES.PERSONALITY_DIMENSIONS ||
        reportData?.buckets[0].name === BUCKET_NAMES.IPIP_LONG ||
        reportData?.buckets[0].name === BUCKET_NAMES.IPIP_SHORT
      )
    ) return true;
    return false
  }, [reportData?.buckets]);

  const handleTraitHover = (id) => {
    if (id) {
      const refId = setTimeout(() => setActiveTrait(id), 400);
      hoverDelayRef.current = refId;
    } else {
      clearTimeout(hoverDelayRef.current);
      setActiveTrait(null);
    }
  };

  const renderSpecialBuckets = (bucket) => {
    const { score: { score, lowerBound, upperBound, median } } = bucket ?? {};
    const color = bucket
      ? getTraitBand({
        score,
        lb: lowerBound,
        ub: upperBound,
        median,
      })?.color
      : "#000";

    return (
      <div className="overall-score">
        <div className="bucket-info">
          <h4 className="bucket-info--score" style={{ color }}>
            {roundOff(score)}
          </h4>
          <div>
            <h4 className="bucket-info--name">{bucket.name}</h4>
            {/* <p className="bucket-info--description">
                Some description...
              </p> */}
            {bucket.traitsMeta && (
              <div className="traits-list">
                {Object.values(bucket?.traitsMeta ?? {}).map(({ id, name }) => (
                  <h2
                    key={id}
                    className={`trait-box small v2 v2--single-row-bucket`}
                  >
                    {name}
                  </h2>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="bucket-info--view-btn-container">
          <button
            className="bucket-info--view-btn"
            onClick={() => setActiveBucketId(bucket.id)}
          >
            View Details
          </button>
        </div>
      </div>
    );
  };

  const activeBucketData = activeBucketId
    ? [
      ...functionalBuckets?.buckets,
      ...communicationBuckets?.buckets,
      ...competenciesBuckets?.buckets,
      ...aptitudeBuckets?.buckets
    ].find(
      ({ id }) => id === activeBucketId
    )
    : null;

  const hideFitScore = useMemo(() => {
    if (!positionConfig?.positionId) return true;

    const { positionId } = positionConfig;
    const hiddenFitScore = TEMP_NO_FITSCORE_POSITIONS.includes(positionId);

    return hiddenFitScore || checkOnlyPersonalityBucket;
  }, [positionConfig, reportData?.buckets]);

  return (
    <div id="one-page-report-component" className="v2">
      <div className="info v2">
        <ReportHeader
          hideCta
          reportData={reportData}
          linkConfig={linkConfig}
          positionConfig={positionConfig}
          isNonDnaReport={isNonDnaReport}
          logMixpanelEvent={logMixpanelEvent}
          setShowSnapshots={() => setIsSnapshotsModalVisible(true)}
          setShowVideoModal={() => setIsVideoModalVisible(true)}
          criticalSnapshotsCount={positionConfig?.aiProctoring ? criticalSnapshots?.length : reportData?.proctoredImages?.critical}
          totalSnapshotsCount={positionConfig?.aiProctoring ? allSnapshots?.length : reportData?.proctoredImages?.total}
        />
        <div className="content-wrapper">
          <div className="max-width-left">
            {!activeBucketId ? (
              <div>
                {
                  hideFitScore ?
                    <></> :
                    <FitScore
                      {...reportData?.fitScore}
                      dnaScore={null}
                      showLegends={isNonDnaReport}
                      fitScoreMultiplier={positionConfig?.fitScoreMultiplier ?? 1}
                    />
                }
                {!!functionalBuckets.buckets?.length > 0 && (
                  <div className="single-bucket">
                    <div>
                      <h4 className="single-bucket--name">
                        {functionalBuckets.name}
                      </h4>
                      <p className="single-bucket--description">
                        {functionalBuckets.description}
                      </p>
                    </div>
                    <div>
                      {functionalBuckets.buckets.map((bucket) =>
                        renderSpecialBuckets(bucket)
                      )}
                    </div>
                  </div>
                )}
                {!!communicationBuckets.buckets?.length > 0 && (
                  <div className="single-bucket">
                    <div>
                      <h4 className="single-bucket--name">
                        {communicationBuckets.name}
                      </h4>
                      <p className="single-bucket--description">
                        {communicationBuckets.description}
                      </p>
                    </div>
                    <div>
                      {communicationBuckets.buckets.map((bucket) =>
                        renderSpecialBuckets(bucket)
                      )}
                    </div>
                  </div>
                )}
                {!!competenciesBuckets.buckets?.length > 0 && (
                  <div className="single-bucket">
                    <div>
                      <h4 className="single-bucket--name">
                        {competenciesBuckets.name}
                      </h4>
                      <p className="single-bucket--description">
                        {competenciesBuckets.description}
                      </p>
                    </div>
                    <div>
                      {competenciesBuckets.buckets.map((bucket) =>
                        renderSpecialBuckets(bucket)
                      )}
                    </div>
                  </div>
                )}
                {!!aptitudeBuckets.buckets?.length > 0 && (
                  <div className="single-bucket">
                    <div>
                      <h4 className="single-bucket--name">
                        {aptitudeBuckets.name}
                      </h4>
                      <p className="single-bucket--description">
                        {aptitudeBuckets.description}
                      </p>
                    </div>
                    <div>
                      {aptitudeBuckets.buckets.map((bucket) =>
                        renderSpecialBuckets(bucket)
                      )}
                    </div>
                  </div>
                )}
                {!!reportData &&
                  reportData.buckets?.map(
                    ({ id, name, description, traitsMeta }) =>
                      !Object.values(BUCKET_NAMES).includes(name) ? (
                        <div key={id} className="group-container">
                          <TraitsGrid
                            showBucketWise
                            hideLegends
                            title={name}
                            description={description}
                            traitsMap={getTraitsMap([{ traitsMeta }])}
                            activeTrait={activeTrait}
                            onTraitHover={handleTraitHover}
                          />
                        </div>
                      ) : null
                  )}
                {!!personalityBuckets.traits?.length > 0 &&
                  <PersonalityComponent
                    bucket={personalityBuckets}
                  />
                }
                {!!personalityBucketsIPIPLong.traits?.length > 0 &&
                  <PersonalityComponent
                    bucket={personalityBucketsIPIPLong}
                  />
                }
                {!!personalityBucketsIPIPShort.traits?.length > 0 &&
                  <PersonalityComponent
                    bucket={personalityBucketsIPIPShort}
                  />
                }
              </div>
            ) : (
              activeBucketData && (
                <BucketDetails
                  {...activeBucketData}
                  reportData={reportData}
                  headerTraitColor={"grey"}
                  onBack={() => setActiveBucketId(null)}
                />
              )
            )}
          </div>
        </div>
      </div>
      <SnapshotsModal
        isSnapshotsModalVisible={isSnapshotsModalVisible}
        setIsSnapshotsModalVisible={setIsSnapshotsModalVisible}
        allSnapshots={allSnapshots}
        criticalSnapshots={criticalSnapshots}
        linkConfig={linkConfig}
        positionConfig={positionConfig}
      />
      <ProctoredMediaModal
        isVideoModalVisible={isVideoModalVisible}
        setIsVideoModalVisible={setIsVideoModalVisible}
        videoSessionsList={videoSessionsList}
        linkConfig={linkConfig}
        positionConfig={positionConfig}
      />
    </div>
  );
};
